// Modules exported here will be included in bundle.js
import 'whatwg-fetch';
import { GenerateReviews} from './GenerateReviews.js';
export { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";
export { GenerateReviews };

function analyticsEventBuilder(name, options) {
  const defaultOptions = { verticalConfigId: 'healthcare_facilities' };
  const e = new ANSWERS.AnalyticsEvent(name);
  e.addOptions({
    ...defaultOptions,
    ...options,
  });

  return e;
}

export const mapOnCreate = function() {
  const isUniversalSearch = document.querySelector('.js-answersUniversalResults');

  const cardInfo = (info) => {
    let teaserCard = document.querySelector('.js-card-' + info.id),
        distanceValue,
        ordinal;
    if (teaserCard) {
      distanceValue = teaserCard.querySelector('.HitchhikerLocationStandard-distance').textContent;
      ordinal = teaserCard.querySelector('.HitchhikerLocationStandard-ordinal').textContent;
    }
    let linkToMap = Formatter.getDirectionsUrl(info);
    let line1 = '';
    if (info.address.line1) {
      line1 = `
        <span class="Popup-addressline">
          ${info.address.line1}
        </span>`
    }
    let line2 = '';
    if (info.address.line2) {
      line2 = `
        <span class="Popup-addressline">
          ${info.address.line2}
        </span>`
    }
    let phone = '';
    if (info.mainPhone) {
      phone = `
        <a href="tel:${info.mainPhone}" class="Popup-phone js-analytics" data-type="TAP_TO_CALL" data-label="blurb_call">
          <img src="static/assets/images/icon-phone.svg" alt="" class="Popup-phoneIcon">
          <span class="Popup-phoneText">
            ${Formatter.nationalizedPhoneDisplay(info)}
          </span>
        </a>`
    }
    let distance = '';
    if (distanceValue) {
      distance = `
        <div class="Popup-distance">
          ${distanceValue}
        </div>
      `
    }
    return `
      <div class="Popup-card">
        <div class="Popup-info">
          <div class="Popup-ordinal">
            ${ordinal}
          </div>
          <button class="Popup-close js-analytics" data-type="VIEW_WEBSITE" data-label="blurb_exit">
            <img src="static/assets/images/icon-close.svg" alt="" class="Popup-closeIcon">
          </button>
          <a href="${info.website}" class="Popup-name js-analytics" data-type="TITLE_CLICK" data-label="blurb_title_click" target="_top">
            ${info.name}
          </a>
          <div class="Popup-address">
            ${line1}
            ${line2}
            <span class="Popup-addressline">
              ${info.address.city}, ${info.address.region} ${info.address.postalCode}
            </span>
          </div>
          ${phone}
          <div class="Popup-row">
            <a href="${linkToMap}" class="Popup-getDirections js-analytics" target="_blank" rel="noopener noreferrer" data-type="CTA_CLICK" data-label="blurb_get_directions">
              <img src="static/assets/images/icon-directions.svg" alt="" class="Popup-directionsIcon">
              <span class="Popup-directionsText">
                Get Directions
              </span>
            </a>
            ${distance}
          </div>
        </div>
      </div>
    `;
  }

  const cardTemplate = (locations) => {
    const multiCard = locations.length > 1;
    if (!multiCard) {
      return `
        <div class="Popup">
          <div class="Popup-body js-popup-body">
            ${cardInfo(locations[0])}
          </div>
        </div>
      `
    }

    const total = locations.length;
    return `
      <div class="Popup">
        <div class="Popup-body js-popup-body">
          ${cardInfo(locations[0])}
        </div>
      </div>
    `
  }

  this._map = this.getProviderInstance(this._mapProvider);
  this._map._collapseMarkers = (markers) => {
    const locationToItem = {};
    markers.forEach(m => {
      locationToItem[`${m.latitude.toFixed(3)}${m.longitude.toFixed(3)}`]
        ? locationToItem[`${m.latitude.toFixed(3)}${m.longitude.toFixed(3)}`].push(m)
        : locationToItem[`${m.latitude.toFixed(3)}${m.longitude.toFixed(3)}`] = [m];
    });

    const collapsedMarkers = [];
    for (let [, markers] of Object.entries(locationToItem)) {
      if (markers.length > 1) {
        const collapsedMarker = {
          item: markers.map(m => m.item),
          label: markers.length,
          latitude: markers[0].latitude,
          longitude: markers[0].longitude
        };
        collapsedMarkers.push(collapsedMarker);
      } else {
        collapsedMarkers.push(markers[0]);
      }
    }

    return collapsedMarkers;
  }

  this._map.loadJS = (onLoad) => {
    let script = document.createElement('script');
    script.id = 'yext-map-js';
    script.onload = () => {
      this._map._isLoaded = true;
      mapboxgl.accessToken = this._map._apiKey;

      if (typeof onLoad === 'function') {
        onLoad();
      }

      if (typeof this._map._onLoaded === 'function') {
        this._map._onLoaded();
      }
    };
    script.async = true;
    script.src = 'https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.js';

    let css = document.createElement('link');
    css.id ='yext-map-css';
    css.rel = 'stylesheet';
    css.href = 'https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.css';

    document.body.appendChild(css);
    document.body.appendChild(script);
  }

  // Save this reference so we can use it inside init()
  const analyticsReporter = this.analyticsReporter;

  this._map.init = function (el, mapData, resultsContext) {
    if ((!mapData || mapData.mapMarkers.length <= 0) && !this._showEmptyMap) {
      this._map = null;
      return this;
    }

    let container = el;
    this._map = new mapboxgl.Map({
      container: container,
      zoom: this._zoom,
      style: 'mapbox://styles/mapbox/streets-v9',
      center: this.getCenterMarker(mapData)
    });

    this._map.addControl(new mapboxgl.NavigationControl({showCompass: false}));

    if (mapData && mapData.mapMarkers.length) {
      let collapsedMarkers = this._collapsePins
        ? this._collapseMarkers(mapData.mapMarkers)
        : mapData.mapMarkers;

      if (collapsedMarkers.length > 5 && isUniversalSearch) {
        collapsedMarkers = collapsedMarkers.slice(0, 5);
      }

      const bounds = new mapboxgl.LngLatBounds();
      for (let i=0; i < collapsedMarkers.length; i++) {
        let marker = collapsedMarkers[i]
        const itemArray = Array.isArray(marker.item) ? marker.item : [marker.item];
        const ids = itemArray.map(item => item.id);
        const cards = document.querySelectorAll(ids.map(id => '.js-card-' + id).join(','));

        const cardsById = Array.from(cards).reduce((m, card) => {
          m[card.dataset.id] = card;
          return m;
        }, {});

        const coords = [marker.longitude, marker.latitude];
        const popup = new mapboxgl.Popup({
          closeButton: false,
          offset: {
            'left': [12, -36],
            'right': [-12, -36],
            'bottom': [0, -56],
            'bottom-left': [12, -48],
            'bottom-right': [-12, -48],
          },
          anchor: 'bottom'
        });
        const parser = new DOMParser();
        let index = i + 1;
        const pinSVG_single = `<svg width="26px" height="32px" viewBox="0 0 26 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>et:map-pin</title>
              <g id="Global-Search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Global-Search---Locations" transform="translate(-900.000000, -594.000000)">
                      <g id="et:map-pin" transform="translate(901.000000, 595.000000)">
                          <rect id="ViewBox" x="1" y="3" width="19" height="24"></rect>
                          <g id="Group" fill="#003B5C" fill-rule="nonzero" stroke="#000000" class="svg-fill">
                              <path d="M12,-0.5 C15.4424637,-0.5 18.5628466,0.817257139 20.8233965,2.94035901 C23.0958611,5.07465118 24.5,8.02220642 24.5,11.2703651 C24.5,20.9221829 13.1112524,29.9105592 12.6241261,30.2877671 C11.0116914,29.9765045 -0.5,20.8262422 -0.5,11.2703651 C-0.5,8.02220642 0.904138863,5.07465118 3.17660349,2.94035901 C5.43715335,0.817257139 8.55753635,-0.5 12,-0.5 L12,-0.5 Z" id="Shape"></path>
                          </g>
                          <text text-anchor="middle" font-family="Gibson Medium" font-size="16" font-weight="bold" fill="#FFF">
                            <tspan x="46%" y="19">${index}</tspan>
                          </text>
                      </g>
                  </g>
              </g>
          </svg>`;
        const pinSVG_multiple = `<svg class="Map-mappin" viewBox="0 0 28 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <path stroke="black" d="M14 5C6.3 5 0 11.312 0 19.027 0 29.548 14 42 14 42s14-12.452 14-22.973C28 11.312 21.7 5 14 5z" fill="#003b5c"/>
              <text text-anchor="middle" font-family="Gibson Medium" font-size="16" font-weight="bold" fill="#FFF">
                <tspan x="50%" y="25">H</tspan>
              </text>
            </g>
          </svg>`;
        const pinSVG = itemArray.length > 1 ? pinSVG_multiple : pinSVG_single;
        const doc = parser.parseFromString(`<button class="js-map-pin">${pinSVG}</button>`, 'text/html');
        const el = doc.body.firstChild;
        const mapMarker = new mapboxgl.Marker({element: el, anchor: 'bottom'})
          .setLngLat(coords)
          .setPopup(popup);

        bounds.extend(mapMarker.getLngLat());
        mapMarker.addTo(this._map);

        popup.on('open', () => {
          popup.setHTML(cardTemplate(itemArray));

          const entityId = itemArray[0].id;

          let closeButton = popup._container.querySelector('.Popup-close');
          closeButton.addEventListener('click', function() {
            popup.remove();
          })

          const firstCard = Array.from(cards).find(card => card.classList.contains(`js-card-${entityId}`));

          let preselected = document.querySelector('.yxt-Answers-component.is-active');
          if (preselected) {
            preselected.classList.remove('is-active');
          }

          let parentEl = firstCard.parentElement.parentElement;
          parentEl.classList.add('is-active');

          // Setup analytics events for infowindow links
          const ctaLabelBaseStr = isUniversalSearch ? 'universal' : 'locations';
          const searcher = isUniversalSearch ? 'UNIVERSAL' : 'VERTICAL';

          analyticsReporter.report(analyticsEventBuilder('VIEW_WEBSITE', {
            entityId,
            searcher,
            ctaLabel: `${ctaLabelBaseStr}_map_click_pin`
          }));

          for (const analyticsItem of popup._container.querySelectorAll('.js-analytics')) {
            analyticsItem.addEventListener('click', () => {
              const { type, label } = analyticsItem.dataset;
              analyticsReporter.report(analyticsEventBuilder(type, {
                entityId,
                searcher,
                ctaLabel: `${ctaLabelBaseStr}_map_${label}`
              }));
            });
          }

          // Scroll the teaser for the clicked mappin into view
          !isUniversalSearch && firstCard.scrollIntoView({behavior: 'smooth', block: 'center'});
        });

        popup.on('close', () => {
          cards.forEach(card => card.classList.remove('is-clicked'));
        })

        mapMarker.getElement().addEventListener('click', (e) => {
          const pins = document.querySelectorAll('.js-map-pin');
          el.classList.toggle('is-clicked');
          pins.forEach(pin => pin != el && pin.classList.remove('is-clicked'));
          let flyToConfig = {center: coords};
          if (isUniversalSearch) {
            flyToConfig.offset = [0, 150]
          } else {
            flyToConfig.offset = [0, 90]
          }
          this._map.flyTo(flyToConfig);
        });

        mapMarker.getElement().addEventListener('mouseover', (e) => {
          el.classList.add('is-hovered');
          cards.forEach(card => card.classList.add('is-hovered'));
        });

        mapMarker.getElement().addEventListener('mouseout', (e) => {
          el.classList.remove('is-hovered');
          cards.forEach(card => card.classList.remove('is-hovered'));
        });

        // clicking a teaser card should pan to the pin & open popup
        cards.forEach(card => {
          card.addEventListener('click', e => {
            // If the click is on a button or link, don't pan or open popup
            const ignoreTypes = ['BUTTON', 'A'];
            if (ignoreTypes.includes(e.target.tagName)) { return; }

            this._map.flyTo({
              center: coords,
            });

            // Open popup
            mapMarker.getPopup().remove();
            mapMarker.getElement().click();
          });
        });
      }
      if (collapsedMarkers.length >= 2) {
        this._map.fitBounds(bounds, { padding: 50 });
      }
    }
  }

  this._map.loadJS();
}
