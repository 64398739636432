export class GenerateReviews {
  constructor() {
    this.businessID = '2102599';
    this.perPage = '10';
    this.npiToElemMap = new Map();
    this.resultCache = {};

    this.baseUrl = `https://w5aug03g03.execute-api.us-east-1.amazonaws.com/prod/reviews/person/${this.businessID}`
    if (HitchhikerJS.isStaging(['yextpages.net'])) {
      this.baseUrl = `https://xnfnpm1h93.execute-api.us-east-1.amazonaws.com/dev/reviews/person/${this.businessID}`
    }

    this.fetchProviderNPIs();
  }

  async fetchProviderNPIs() {
    this.reviewSummaryEls = document.querySelectorAll('.ds-summary');
    this.reviewSummaryEls.forEach(el => {
      const npi = el.dataset.npi;
      if (this.npiToElemMap.has(npi)) {
        this.npiToElemMap.get(npi).push(el);
      } else {
        this.npiToElemMap.set(el.dataset.npi, [el]);
      }
    });
    const npiList = Array.from(this.npiToElemMap.keys()).join(",");
    this.getProviderComments(npiList).then((resp) => {
      for (const reviewEntity of resp.entities) {
        this.npiToElemMap.get(reviewEntity.id).forEach(el => {
          this.renderProviderReviews(el,reviewEntity);
        });
      }
    });
  }

  renderProviderReviews(el,reviewEntity) {
    const starsMap = new Map([
      [0, 'ds-stars0'],
      [1 , 'ds-stars1'],
      [1.5, 'ds-stars15'],
      [2, 'ds-stars2'],
      [2.5, 'ds-stars25'],
      [3, 'ds-stars3'],
      [3.5, 'ds-stars35'],
      [4, 'ds-stars4'],
      [4.5, 'ds-stars45'],
      [5, 'ds-stars5']
    ]);

    const rating = reviewEntity.overallRating.value;
    const roundedRating = Math.round(rating * 2) / 2
    const numberOfStars = starsMap.get(roundedRating) || starsMap.get(0);
    const numberOfComments = reviewEntity.totalCommentCount;
    const numberOfRatings = reviewEntity.totalRatingCount;

    let reviewHTML = `
    <div class="ds-summary" itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating">
      <div class="ds-title">Patient Rating</div>
      <div>
        <span class="ds-stars ` + numberOfStars + `"></span>
      </div>
      <div class="ds-xofy">
        <span class="ds-average" itemprop="ratingValue">` + rating + `</span>
        <span class="ds-average-max">out of 5</span>
      </div>
      <div class="ds-ratings">
        <span class="ds-ratingcount" itemprop="reviewCount">` + numberOfRatings + `</span> verified patient reviews
      </div>
      <div class="ds-comments">
        <span class="ds-commentcount">
          ` + numberOfComments + `Comments
        </span>
      </div>
    </div>`

    if (rating == null || numberOfRatings === 0) {
      reviewHTML = ''
    }

    el.outerHTML = reviewHTML;
  }

  async getProviderComments(npiList, page = 1) {
    const queryUrl = `${this.baseUrl}/${npiList}?perPage=${this.perPage}&page=${page}`;
    return await fetch(queryUrl,{
        method: 'GET'
      }).then(async (resp) => {
      if(resp.status === 200) {
        return await resp.json();
      }
    });
  }
}
